import { DbTenderTerritoryLight, TenderTerritoryLight } from './tender-territory';
import { DbStatusItem, StatusItem } from './tender-status';
import { TenderTypesEnum } from "./tender-search-body";


export interface DbTender {
  id: string;
  title: string;
  tender_type: string;
  inquirers: string[];
  indexation_date: string;
  closing_date?: string;                      // may be null from api
  territories: DbTenderTerritoryLight[];      // may be equal to []
  source?: string;
  market_type?: string[];
  url?: string;
  dce_project_uid: string;
  is_suggestion?: boolean;
  is_relevant?: boolean;
  cluster_id?: number[];
  score?: number;
  status?: DbStatusItem;
  note?: string;
}

export class Tender {
  id: string;
  title: string;
  tenderType: string;
  inquirers: string[];
  indexationDate: Date;
  closingDate?: Date;
  territories: TenderTerritoryLight[];
  source?: string;
  marketType?: string[];
  url?: string;
  dceProjectUid: string;
  daysLeft?: number;
  isSuggestion?: boolean;
  isRelevant?: boolean;
  cluster_id?: number[];
  score?: number;
  status?: StatusItem;
  note?: string;

  hide?: boolean;

  constructor(dbTender: DbTender) {
    this.id = dbTender.id;
    this.title = dbTender.title;
    this.tenderType = dbTender.tender_type ?? 'undetermined';
    this.inquirers = dbTender.inquirers;
    this.indexationDate = new Date(dbTender.indexation_date);
    this.closingDate = dbTender.closing_date ? new Date(dbTender.closing_date) : undefined;
    this.source = dbTender.source;
    this.territories = dbTender.territories
        .map((dbTTL: DbTenderTerritoryLight) => new TenderTerritoryLight(dbTTL))
        .sort((a,b) => a.code.localeCompare(b.code));
    this.marketType = dbTender.market_type?.sort();
    this.url = dbTender.url;
    this.dceProjectUid = dbTender.dce_project_uid;
    this.daysLeft = this.tenderType === TenderTypesEnum.ATTRIBUTION ? -1 :
      this.closingDate?.getTime() && Math.ceil((this.closingDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    this.isSuggestion = dbTender.is_suggestion ?? false;
    this.isRelevant = dbTender.is_relevant;
    this.cluster_id = dbTender.cluster_id;
    this.score = dbTender.score;
    this.status = dbTender?.status ? new StatusItem(dbTender.status) : undefined;
    this.note = dbTender?.note;
  }
}
