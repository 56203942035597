{
  "explain_version" : "1.18.13",

  "display_update_modal": true,

  "__comment__": "__to use display_new_feature_explained_modal, you NEED to set display_update_modal to true__",
  "display_new_feature_explained_modal": false,
  "__comment2__": "__for now on, new_feature_localstorage_item_name HAS to be unique for each new feature__",
  "new_feature_localstorage_item_name": "impacters-articles-feature-explained-modal-release-24012023-SEEN",

  "other": "0"
}
