import { booleanAttribute, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Tender} from '../../models/tender';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { ModalShareWatchComponent } from '../modal-share-watch/modal-share-watch.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TenderTerritoriesTextService } from "../../services/helpers/tender-territories-text.service";
import { getSanitizedString } from "../../services/helpers/string-helper";

@Component({
  selector: 'app-tender-share',
  templateUrl: './tender-share-btn.component.html',
  styleUrls: ['./tender-share-btn.component.scss']
})
export class TenderShareBtnComponent implements OnChanges {

  @Input({required: true}) tender!: Tender;
  @Input() size: 'small' | 'x-small' = 'x-small';
  @Input({transform: booleanAttribute}) displayShareIcon = false;
  @Input({transform: booleanAttribute}) displayShareText = false;
  inquirers!: string;
  departments!: string;

  @ViewChild('tenderListActionToast', {read: TemplateRef}) tenderListActionToast!: TemplateRef<any>;

  constructor(protected tenderTerritoriesTextService: TenderTerritoriesTextService,
              private toastMessageStackService: ToastMessageStackService,
              private modalService: NgbModal,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tender']) {
      this.inquirers = getSanitizedString((this.tender.inquirers ?? []).join(', '));
      this.departments = this.tenderTerritoriesTextService.getCompactString(this.tender.territories);
    }
  }

  shareMarket() {
    const modalRef = this.modalService.open(
      ModalShareWatchComponent,
      {ariaLabelledBy: 'modal-share-watch', centered: true, windowClass: 'modal-auto'});
    modalRef.componentInstance.tender = this.tender;
    modalRef.componentInstance.inquirers = this.inquirers;
    modalRef.componentInstance.department = this.departments;
    modalRef.componentInstance.note = this.tender.note ?? '';
    modalRef.result.then((contentToCopy) => {
      navigator.clipboard.writeText(contentToCopy);
      this.showToast('copy', 'success');
    }).catch(() => null);

  }

  showToast(action: 'copy', message: 'success' | 'error', entityName?: string) {
    let options: any = {autohide: true, classname: message + '-toast-tenders' + ' toast-shape ' + action, action: action};
    if (entityName) options = {...options, name: entityName};
    this.toastMessageStackService.show(this.tenderListActionToast, options);
  }
}
